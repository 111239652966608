import { gql, useMutation } from "@apollo/client";

export const DELETE_ATTRIBUTE = gql`
  mutation DeleteProductAttribute($id: String!) {
    deleteOneProductAttribute(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteProductAttribute = (onCompleted?: () => void) => {
  const [deleteProductAttribute, { data, loading, error }] = useMutation(
    DELETE_ATTRIBUTE,
    {
      onCompleted,
    }
  );

  const handleDeleteAttribute = async (
    id: string,
    image: string | undefined
  ) => {
    try {
      const result = await deleteProductAttribute({ variables: { id } });

      if (image) {
        await fetch(`${process.env.REACT_APP_API_URL}/delete-image/${image}`, {
          method: "DELETE",
        });
      }
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeleteAttribute,
    data,
    loading,
    error,
  };
};
