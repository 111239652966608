import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateOrder } from "../../../graphql/mutations/Landing/Order/updateOrder";
import { useGetAllOrders } from "../../../graphql/queries/Landing/Order/getOrders";

const EditOrder = () => {
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useGetAllOrders();
  const { updateOrders } = useUpdateOrder(refetch);
  const navigation = useNavigate();

  useEffect(() => {
    if (data && data.length > 0) {
      form.setFieldsValue({
        kaName: data[0].kaName,
        enName: data[0].enName,
        ruName: data[0].ruName,
        kaDescription: data[0].kaDescription,
        enDescription: data[0].enDescription,
        ruDescription: data[0].ruDescription,
      });
    }
  }, [data, form]);

  const onFinish = async (values: {
    kaName: string;
    ruName: string;
    enName: string;
    kaDescription: string;
    enDescription: string;
    ruDescription: string;
  }) => {
    const {
      kaName,
      ruName,
      enName,
      kaDescription,
      enDescription,
      ruDescription,
    } = values;

    try {
      await updateOrders({
        variables: {
          where: {
            id: id, // the unique ID of the order you want to update
          },
          data: {
            kaName: { set: kaName },
            enName: { set: enName },
            ruName: { set: ruName },
            kaDescription: { set: kaDescription },
            enDescription: { set: enDescription },
            ruDescription: { set: ruDescription },
          },
        },
      });

      navigation("/landing/order");

      message.success("პროდუქტის სახეობა წარმატებით განახლდა");
    } catch (error) {
      message.error("პროდუქტის სახეობის განახლება ვერ მოხერხდა");
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: "30px" }}
    >
      <Form.Item
        label="სახელი (ქართული)"
        name="kaName"
        rules={[
          { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
        ]}
      >
        <Input placeholder="სახელი" />
      </Form.Item>
      <Form.Item
        label="სახელი (ინგლისური)"
        name="enName"
        rules={[
          { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
        ]}
      >
        <Input placeholder="სახელი" />
      </Form.Item>
      <Form.Item
        label="აღწერა (ქართული)"
        name="kaDescription"
        rules={[
          { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ქართულად" },
        ]}
      >
        <Input placeholder="აღწერა" />
      </Form.Item>
      <Form.Item
        label="აღწერა (ინგლისური)"
        name="enDescription"
        rules={[
          { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ინგლისურად" },
        ]}
      >
        <Input placeholder="აღწერა" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          განახლება
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditOrder;
