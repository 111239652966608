import { Spin, Table, Button, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { useGetAllGalleryImages } from "../../../graphql/queries/gallery/Gallery-image/getImage";
import { useDeleteGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/deleteImage";
import { ApolloError } from "@apollo/client";
import Title from "antd/es/typography/Title";

const ImageList = () => {
  const { data, loading, error, refetch } = useGetAllGalleryImages();
  const { handleDeleteImage } = useDeleteGalleryImage(refetch);

  if (loading) return <Spin />;
  if (error) return <p>Error loading images.</p>;

  const columns = [
    {
      title: "სახელი",
      key: "name",
      render: (_: any, record: any) => (
        <div className="flex flex-col gap-2">
          {record.translations.map((translation: any) => (
            <div key={translation.id}>
              <span className="font-semibold">{translation.language}: </span>
              {translation.name || "N/A"}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "ქმედება",
      key: "action",
      render: (_: any, record: any) => (
        <Button.Group>
          <Link to={`/gallery/image/edit/${record.id}`}>
            <Button type="primary">რედაქტირება</Button>
          </Link>
          <Popconfirm
            title="გსურთ წაშლა?"
            onConfirm={async () => {
              const { success, error } = await handleDeleteImage(
                record.id,
                record.images
              );
              if (success) {
                message.success("სიახლე წაიშალა");
                refetch();
              } else {
                if (error instanceof ApolloError) {
                  message.error(`შეცდომა წაშლისას: ${error.message}`);
                } else {
                  message.error("შეცდომა წაშლისას: უცნობი შეცდომა");
                }
              }
            }}
            okText="კი"
            cancelText="არა"
          >
            <Button type="primary" danger>
              წაშლა
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={loading}
        title={() => (
          <div>
            <div className="BlogTitle-addButton">
              <Title level={3}>სურათები</Title>
              <Link to={"/gallery/image/create"}>
                <Button type="primary">დამატება</Button>
              </Link>
            </div>
          </div>
        )}
        tableLayout="fixed"
        bordered
      />
    </>
  );
};

export default ImageList;
