import { gql } from "@apollo/client";
import { useMutation, ApolloError } from "@apollo/client";

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: String!) {
    deleteOneProduct(where: { id: $id }) {
      id
    }
  }
`;

// export const useDeleteProductMutation = () => {
//   return useMutation(DELETE_PRODUCT);
// };

export const useDeleteProductMutation = (refetchProducts: () => void) => {
  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      refetchProducts();
    },
  });

  const handleDeleteProduct = async (
    id: string,
    mainImageUrl: string,
    additionalImages: any
  ) => {
    try {
      const result = await deleteProduct({
        variables: { id },
      });

      await fetch(
        `${process.env.REACT_APP_API_URL}/delete-image/${mainImageUrl}`,
        {
          method: "DELETE",
        }
      );

      if (additionalImages && additionalImages.length > 0) {
        const deleteImagesPromises = additionalImages.map((images: any) =>
          fetch(`${process.env.REACT_APP_API_URL}/delete-image/${images}`, {
            method: "DELETE",
          })
        );

        await Promise.all(deleteImagesPromises);
      }

      return { success: true, data: result.data };
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(
          "Apollo Error:",
          error.message,
          error.graphQLErrors,
          error.networkError
        );
      } else {
        console.error("Unknown error:", error);
      }
      return { success: false, error };
    }
  };

  return { handleDeleteProduct, loading };
};
