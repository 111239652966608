import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const UPDATE_ONE_ORDER = gql`
  mutation UpdateOneOrder(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOneOrder(where: $where, data: $data) {
      id
      kaName
      enName
      kaDescription
      enDescription
    }
  }
`;

export const useUpdateOrder = (refetchAttributes: () => void) => {
  const [updateOrders, { loading, error, data }] = useMutation<{ data: any }>(
    UPDATE_ONE_ORDER,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { updateOrders, loading, error, data };
};
