import { Button, Form, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import { useCreateOrder } from "../../../graphql/mutations/Landing/Order/createOrder";

const CreateOrder = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const { createOrder } = useCreateOrder(() => console.log("refetch"));

  const onFinish = async (values: any) => {
    const { kaName, enName, kaDescription, enDescription } = values;

    try {
      await createOrder({
        variables: {
          data: {
            kaName: kaName,
            enName: enName,
            kaDescription: kaDescription,
            enDescription: enDescription,
          },
        },
      });

      navigation("/landing/order");
      message.success("'შეუკვეთე' წარმატებით შეიქმნა");
      form.resetFields();
    } catch (error) {
      message.error("'შეუკვეთე' შექმნა ვერ მოხერხდა");
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <Title level={2}>"შეუკვეთეს" დამატება</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <Form.Item
          label="სახელი (ქართული)"
          name="kaName"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (ინგლისური)"
          name="enName"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="აღწერა (ქართული)"
          name="kaDescription"
          rules={[
            { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ქართულად" },
          ]}
        >
          <Input placeholder="აღწერა" />
        </Form.Item>

        <Form.Item
          label="აღწერა (ინგლისური)"
          name="enDescription"
          rules={[
            { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ინგლისურად" },
          ]}
        >
          <Input placeholder="აღწერა" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // loading={loading}
          >
            შენახვა
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateOrder;
