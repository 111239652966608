import { gql } from "@apollo/client";

export const UPDATE_COMPANY_INFO = gql`
  mutation UpdateCompanyInfo($id: String!, $data: CompanyInfoUpdateInput!) {
    updateOneCompanyInfo(where: { id: $id }, data: $data) {
      id
      gmail
      number
      fbLink
      instaLink
      linkedLink
      whatsLink
      addressLink
      translations {
        id
        language
        address
        text
      }
    }
  }
`;
