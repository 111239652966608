import { gql, useMutation, ApolloError } from "@apollo/client";

export const DELETE_ONE_VIDEO = gql`
  mutation DeleteOneGalleryVideo($id: String!) {
    deleteOneGalleryVideo(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteVideo = (refetchVideos: () => void) => {
  const [deleteVideo, { loading }] = useMutation(DELETE_ONE_VIDEO, {
    onCompleted: () => {
      refetchVideos();
    },
  });

  const handleDeleteVideo = async (
    id: string,
    videoName: string | undefined,
    thumbnailName: string | undefined
  ) => {
    try {
      const result = await deleteVideo({
        variables: { id },
      });

      await fetch(
        `${process.env.REACT_APP_API_URL}/delete-video/${videoName}`,
        {
          method: "DELETE",
        }
      );

      if (thumbnailName) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/delete-image/${thumbnailName}`,
          {
            method: "DELETE",
          }
        );
      }

      return { success: true, data: result.data };
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(
          "Apollo Error:",
          error.message,
          error.graphQLErrors,
          error.networkError
        );
      } else {
        console.error("Unknown error:", error);
      }
      return { success: false, error };
    }
  };

  return { handleDeleteVideo, loading };
};
