import { gql, useMutation } from "@apollo/client";

export const DELETE_ABOUT_US = gql`
  mutation DeleteAboutUs($id: String!) {
    deleteOneAboutUs(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteAboutUs = (onCompleted?: () => void) => {
  const [deleteAboutUs, { data, loading, error }] = useMutation(
    DELETE_ABOUT_US,
    {
      onCompleted,
    }
  );

  const handleDeleteImage = async (id: string, images: any) => {
    try {
      const result = await deleteAboutUs({ variables: { id } });

      if (images && images.length > 0) {
        const deleteImagesPromises = images.map((image: any) =>
          fetch(`${process.env.REACT_APP_API_URL}/delete-image/${image}`, {
            method: "DELETE",
          })
        );

        await Promise.all(deleteImagesPromises);
      }
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeleteImage,
    data,
    loading,
    error,
  };
};
