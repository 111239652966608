import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const CREATE_ONE_ORDER = gql`
  mutation CreatePackageSize($data: OrderCreateInput!) {
    createOneOrder(data: $data) {
      id
      enDescription
      enName
      kaDescription
      kaName
    }
  }
`;

export const useCreateOrder = (refetchAttributes: () => void) => {
  const [createOrder, { loading, error, data }] = useMutation<{ data: any }>(
    CREATE_ONE_ORDER,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { createOrder, loading, error, data };
};
