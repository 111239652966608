import { Fragment, useEffect, useState } from "react";
import { Form, Button, Card, message, Upload, Row, Col, Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UploadChangeParam } from "antd/es/upload/interface";
import { useGetOneGalleryVideo } from "../../../graphql/queries/gallery/Gallery-video/findVideo";
import { useUpdateGalleryVideo } from "../../../graphql/mutations/Gallery/Gallery-video/updateVideo";
import { GalleryVideoUpdateInput } from "../../../types/gallery/videoTypes";
import { PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";

const EditVideo = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data } = useGetOneGalleryVideo(id!);
  const { updateGalleryVideo, loading: updating } = useUpdateGalleryVideo(() =>
    navigate("/gallery/videos")
  );
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const video = data?.galleryVideo;

  useEffect(() => {
    if (data) {
      setThumbnailUrl(video.thumbnailUrl);
      setVideoUrl(video.url);
    }
  }, [data]);

  const handleThumbnailUpload = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const newThumbnailUrl = info.file.response.url;
      setThumbnailUrl(newThumbnailUrl);
      message.success(`${info.file.name} სურათი აიტვირთა!`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} სურათი ვერ აიტვირთა.`);
    }
  };

  const handleVideoUpload = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const newVideoUrl = info.file.response.url;
      setVideoUrl(newVideoUrl);
      message.success(`${info.file.name} ვიდეო აიტვირთა!`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} ვიდეო ვერ აიტვირთა.`);
    }
  };

  const onFinish = async () => {
    const videoData: GalleryVideoUpdateInput = {
      thumbnailUrl: { set: thumbnailUrl || "" },
      url: { set: videoUrl || "" },
    };

    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/delete-image/${video.thumbnailUrl}`,
        {
          method: "DELETE",
        }
      );
      const videoName = video.url.split("/").pop();

      await fetch(
        `${process.env.REACT_APP_API_URL}/delete-video/${videoName}`,
        {
          method: "DELETE",
        }
      );
      const { data } = await updateGalleryVideo({
        variables: { id: id || "", data: videoData },
      });
      if (data) {
        message.success("ვიდეო რედაქტირებულია!");
        navigate("/gallery/videos");
      } else {
        throw new Error("ვიდეო არ შეიცვალა.");
      }
    } catch (err) {
      message.error(
        "An error occurred: " +
          (err instanceof Error ? err.message : String(err))
      );
    }
  };

  return (
    <Fragment>
      <Card title="ვიდეოს რედაქტირება">
        <Form form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="thumbnail">
                <Upload
                  action={`${process.env.REACT_APP_API_URL}/upload`}
                  listType="picture"
                  onChange={handleThumbnailUpload}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button type="primary" icon={<PictureOutlined />}>
                    შეცვალე სურათი
                  </Button>
                </Upload>
              </Form.Item>

              {thumbnailUrl && (
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${thumbnailUrl}`}
                  alt="Thumbnail"
                  style={{
                    width: "200px",
                    height: "auto",
                    display: "block",
                    margin: "16px 0",
                  }}
                />
              )}
            </Col>

            <Col span={12}>
              <Form.Item name="video">
                <Upload
                  action={`${process.env.REACT_APP_API_URL}/upload`}
                  listType="picture"
                  onChange={handleVideoUpload}
                  showUploadList={false}
                  accept="video/*"
                >
                  <Button type="primary" icon={<VideoCameraOutlined />}>
                    შეცვალე ვიდეო
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "20px", backgroundColor: "#1890ff" }}
              loading={updating}
            >
              შენახვა
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Fragment>
  );
};

export default EditVideo;
