import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const CREATE_ONE_WHOWEARE = gql`
  mutation CreatePackageSize($data: WhoWeAreCreateInput!) {
    createOneWhoWeAre(data: $data) {
      id
      imageUrl
      videoUrl
      enDescription
      kaDescription
      productAttributes {
        id
      }
    }
  }
`;

export const useCreateWhoWeAre = (refetchAttributes: () => void) => {
  const [createWhoWeAre, { loading, error, data }] = useMutation<{ data: any }>(
    CREATE_ONE_WHOWEARE,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { createWhoWeAre, loading, error, data };
};
