import { Button, Form, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import { useCreateLayer } from "../../../../graphql/mutations/Filter/Layer/createLayers";
import { useGetAllLayers } from "../../../../graphql/queries/Filter/Layer/getLayers";

const CreateLayers = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const { refetch } = useGetAllLayers();
  const { createLayer } = useCreateLayer(refetch);

  const onFinish = async (values: any) => {
    const { ka, en } = values;

    try {
      await createLayer({
        variables: {
          data: {
            translations: {
              create: [
                { language: "KA", name: ka },
                { language: "EN", name: en },
              ],
            },
          },
        },
      });
      navigation("/layers");
      message.success("რაოდენობა შეკვრაში წარმატებით შეიქმნა");
      form.resetFields();
    } catch (error) {
      message.error("რაოდენობა შეკვრაში ვერ შეიქმნა");
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <Title level={2}>ფენების რაოდენობის დამატება</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <Form.Item
          label="სახელი (ქართული)"
          name="ka"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (ინგლისური)"
          name="en"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // loading={loading}
            // disabled={!uploadedImage}
          >
            შენახვა
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateLayers;
