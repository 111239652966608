import { gql, useMutation, ApolloError } from "@apollo/client";

export const DELETE_ONE_CONTENT = gql`
  mutation DeleteOnePost($id: String!) {
    deleteOnePost(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteContent = (refetchContents: () => void) => {
  const [deleteContent, { loading }] = useMutation(DELETE_ONE_CONTENT, {
    onCompleted: () => {
      refetchContents();
    },
  });

  const handleDeleteContent = async (
    id: string,
    image: string,
    components: any[]
  ) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/delete-image/${image}`, {
        method: "DELETE",
      });

      const deleteImagePromises = components.flatMap((component: any) => {
        return component.images.map((imageObj: any) => {
          const imageUrl = imageObj.url.split("/").pop();
          return fetch(
            `${process.env.REACT_APP_API_URL}/delete-image/${imageUrl}`,
            {
              method: "DELETE",
            }
          );
        });
      });

      await Promise.all(deleteImagePromises);

      const result = await deleteContent({
        variables: { id },
      });

      return { success: true, data: result.data };
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(
          "Apollo Error:",
          error.message,
          error.graphQLErrors,
          error.networkError
        );
      } else {
        console.error("Unknown error:", error);
      }
      return { success: false, error };
    }
  };

  return { handleDeleteContent, loading };
};
