import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, Spin, List, Row, Col } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UploadFile } from "antd/lib/upload/interface";
import { useGetOneGalleryImage } from "../../../graphql/queries/gallery/Gallery-image/findImage";
import { useDeleteGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/deleteImage";
import { useGetAllGalleryImages } from "../../../graphql/queries/gallery/Gallery-image/getImage";
import { useCreateGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/createImage";

const { Item: FormItem } = Form;

interface ExtendedUploadFile extends UploadFile {
  mobileIndex?: number;
  desktopIndex?: number;
}

interface InitialValues {
  [key: string]: number;
}

const EditImage = () => {
  const { id } = useParams<{ id: any }>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<ExtendedUploadFile[]>([]);
  const [translations, setTranslations] = useState<
    { id: string; name: string; language: string }[]
  >([]);

  const { data, loading: loadingImage } = useGetOneGalleryImage(id);
  const { handleDeleteImage } = useDeleteGalleryImage();

  const { refetch } = useGetAllGalleryImages();
  const { createGalleryImage } = useCreateGalleryImage(() => {
    refetch();
    navigate("/gallery/images");
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const { images, translations } = data;

      if (images && images.length > 0) {
        const formattedFileList = images.map((image: any) => ({
          uid: image.id,
          name: image.url.split("/").pop(),
          status: "done",
          url: `${process.env.REACT_APP_API_URL}/uploads/${image.url}`,
          mobileIndex: image.mobileIndex,
          desktopIndex: image.desktopIndex,
        }));

        setFileList(formattedFileList);

        const initialValues: InitialValues = {};
        formattedFileList.forEach((file: any) => {
          initialValues[`mobileIndex[${file.name}]`] = file.mobileIndex || 0;
          initialValues[`desktopIndex[${file.name}]`] = file.desktopIndex || 0;
        });

        form.setFieldsValue(initialValues);
        setTranslations(translations);
      }
    }
  }, [data, form]);

  const handleSubmit = async () => {
    const uploadPromises = fileList.map(async (file) => {
      if (!file.originFileObj) {
        return { url: file.url, name: file.name };
      }

      const formData = new FormData();
      formData.append("file", file.originFileObj as Blob);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Failed to upload file: ${errorData}`);
      }

      const data = await response.json();

      if (!data || !data.url) {
        throw new Error("Invalid response from upload endpoint");
      }

      return { url: data.url, name: file.name };
    });

    try {
      const uploadedFiles: any = await Promise.all(uploadPromises);

      const imagesData = uploadedFiles.map((fileData: any) => {
        const baseUrl = `${process.env.REACT_APP_API_URL}/uploads/`;
        let imageUrl = fileData.url;

        if (imageUrl.startsWith(baseUrl)) {
          imageUrl = imageUrl.replace(baseUrl, "");
        }

        return {
          url: imageUrl,
          desktopIndex: Number(
            form.getFieldValue(`desktopIndex[${fileData.name}]`)
          ),
          mobileIndex: Number(
            form.getFieldValue(`mobileIndex[${fileData.name}]`)
          ),
        };
      });
      const imagesDataUrls = imagesData.map((image: any) => image.url);

      const unchangedImages = data.images.filter(
        (image: any) => !imagesDataUrls.includes(image.url)
      );

      await handleDeleteImage(id, unchangedImages);

      const updatedTranslations = translations.map((trans) => ({
        name: trans.name,
        language: trans.language,
      }));

      await createGalleryImage({
        variables: {
          data: {
            images: {
              create: imagesData,
            },
            translations: {
              create: updatedTranslations,
            },
          },
        },
      });

      navigate("/gallery/images");
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleTranslationChange = (index: number, value: string) => {
    const newTranslations = [...translations];
    newTranslations[index] = { ...newTranslations[index], name: value };
    setTranslations(newTranslations);
  };

  if (loadingImage) return <Spin />;
  if (!data) return <p>Error loading image.</p>;

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2 style={{ marginBottom: "30px" }}>სურათის ლისტის რედაქტირება</h2>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {fileList.map((file, index) => (
          <Row key={index} gutter={16}>
            <Col span={12}>
              <FormItem
                name={`mobileIndex[${file.name}]`}
                label={`ინდექსი მობაილისთვის - ${file.name}`}
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ ინდექსი მობაილისთვის",
                  },
                ]}
              >
                <Input type="number" min={1} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={`desktopIndex[${file.name}]`}
                label={`ინდექსი დესკტოპისთვის - ${file.name}`}
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ ინდექსი დესკტოპისთვის",
                  },
                ]}
              >
                <Input type="number" min={1} />
              </FormItem>
            </Col>
          </Row>
        ))}

        <Upload
          fileList={fileList}
          beforeUpload={(file) => {
            setFileList((prevList) => [...prevList, file]);
            return false;
          }}
          onChange={handleFileChange}
          listType="picture-card"
          accept="image/*"
          multiple
        >
          <div>
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>

        <List
          dataSource={translations}
          renderItem={(trans, index) => (
            <FormItem key={trans.id}>
              <Input
                value={trans.name}
                onChange={(e) => handleTranslationChange(index, e.target.value)}
                placeholder={`Translation for ${trans.language}`}
              />
            </FormItem>
          )}
        />

        <FormItem>
          <Button type="primary" htmlType="submit">
            რედაქტირება
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default EditImage;
