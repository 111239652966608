import React from "react";
import { Card, Spin, Typography, Alert, Button, message } from "antd";
import { useGetAllAboutUs } from "../graphql/queries/About-us/getAboutUs";
import { Link } from "react-router-dom";
import { useDeleteAboutUs } from "../graphql/mutations/About-us/deleteAboutUs";

const { Title, Paragraph } = Typography;

const AboutUs: React.FC = () => {
  const { data, loading, error, refetch } = useGetAllAboutUs();
  const { handleDeleteImage } = useDeleteAboutUs(refetch);

  const handleDelete = async (id: string, images: any[]) => {
    const result = await handleDeleteImage(id, images);
    if (result.success) {
      message.success("წაშლის წარმატებით შესრულდა!");
    } else {
      message.error("წაშლისას მოხდა შეცდომა!");
    }
  };

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return <Alert message="Error" description={error.message} type="error" />;

  return (
    <div style={{ padding: 20 }}>
      <Title level={2}>კომპანიის შესახებ ინფორმაცია</Title>
      <div style={{ marginTop: 20, textAlign: "center" }}>
        <Link to="/about-us/create">
          <Button type="primary" style={{ marginBottom: 5 }}>
            ახლის დამატება
          </Button>
        </Link>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {data.map((aboutUs: any) => (
          <Card
            key={aboutUs.id}
            bordered={false}
            extra={
              <div style={{ display: "flex", gap: 8 }}>
                <Link to={`/about-us/edit/${aboutUs.id}`}>
                  <Button type="primary">რედაქტირება</Button>
                </Link>
                <Button
                  danger
                  onClick={() => handleDelete(aboutUs.id, aboutUs.imageUrl)}
                >
                  წაშლა
                </Button>
              </div>
            }
            style={{ border: "1px solid #d9d9d9", borderRadius: 8 }}
          >
            <div className=" flex">
              {aboutUs.translations.map((translation: any) => (
                <div
                  key={translation.id}
                  style={{ marginBottom: 10, display: "flex" }}
                >
                  <Title level={4}>{translation.language}</Title>
                  <Paragraph strong>{translation.title}</Paragraph>
                  <Paragraph>{translation.description}</Paragraph>
                </div>
              ))}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
