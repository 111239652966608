import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { CustomLayout } from "./components/Layout";
import { RouterProvider } from "react-router-dom";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import ProductsPage from "./pages/ProductsPage";
import BlogPage from "./pages/BlogPage";
import GetContent from "./components/Blog/Content/GetContent";
import EditContent from "./components/Blog/Content/EditContent";
import AboutUs from "./pages/AboutUs";
import AboutUsForm from "./components/About-us/AboutUsForm";
import AboutUsEditForm from "./components/About-us/AboutUsEditForm";
import HeroPage from "./pages/HeroPage";
import CreateHero from "./components/Hero/Crud/CreateHero";
import EditHero from "./components/Hero/Crud/EditHero";
import GalleryPage from "./pages/GalleryPage";
import CreateVideo from "./components/gallery/Gallery-video/CreateVideo";
import EditVideo from "./components/gallery/Gallery-video/EditVideo";
import CreateImage from "./components/gallery/Gallery-image/CreateImage";
import EditImage from "./components/gallery/Gallery-image/EditImage";
import CreateAttribute from "./components/Products/Attribute/CreateAttribute";
import EditAttribute from "./components/Products/Attribute/EditAttribute";
import CreateProduct from "./components/Products/Products/CreateProduct";
import EditProduct from "./components/Products/Products/EditProduct";
import CreateSpecies from "./components/Products/Filter/Species/CreateSpecies";
import EditSpecies from "./components/Products/Filter/Species/EditSpecies";
import CreatePackage from "./components/Products/Filter/Packages/CreatePackage";
import EditPackage from "./components/Products/Filter/Packages/EditPackage";
import CreateLayers from "./components/Products/Filter/Layers/CreateLayers";
import EditLayer from "./components/Products/Filter/Layers/EditLayer";
import CreateOrder from "./components/Landing/Order/CreateOrder";
import Order from "./components/Landing/Order/Order";
import EditOrder from "./components/Landing/Order/EditOrder";
import WhoWeAre from "./components/Landing/WhoWeAre/WhoWeAre";
import CreateWhoWeAre from "./components/Landing/WhoWeAre/CreateWhoWeAre";
import EditWhoWeAre from "./components/Landing/WhoWeAre/EditWhoWeAre";
import { UnprotectedRoute } from "./components/UI/UnprotectedRoute";
import { Login } from "./pages/login/Login";
import { ProtectedRoute } from "./components/UI/ProtectedRoute";
import Accounts from "./pages/accounts/Accounts";
import CreateAccounts from "./pages/accounts/CreateAccounts";
import EditAccount from "./pages/accounts/EditAccount";
import AttributeList from "./components/Products/Attribute/AttributeList";
import Species from "./components/Products/Filter/Species/Species";
import Packages from "./components/Products/Filter/Packages/Packages";
import Layers from "./components/Products/Filter/Layers/Layers";
import CompanyInfo from "./components/Company-info/CompanyInfo";
import EditCompanyInfo from "./components/Company-info/EditCompanyInfo";
import ImageList from "./components/gallery/Gallery-image/ImageList";
import VideoList from "./components/gallery/Gallery-video/VideoList";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <UnprotectedRoute>
        <Login />
      </UnprotectedRoute>
    ),
  },
  {
    element: (
      <ProtectedRoute>
        <ApolloProvider client={client}>
          <CustomLayout />
        </ApolloProvider>
      </ProtectedRoute>
    ),
    children: [
      { path: "", element: <Navigate to="/blogs" /> },
      { path: "/accounts", element: <Accounts /> },
      { path: "/accounts/create", element: <CreateAccounts /> },
      { path: "/accounts/edit/:id", element: <EditAccount /> },
      { path: "/products", element: <ProductsPage /> },
      { path: "/product/create", element: <CreateProduct /> },
      { path: "/product/edit/:id", element: <EditProduct /> },
      { path: "/attributes", element: <AttributeList /> },
      { path: "/attribute/create", element: <CreateAttribute /> },
      { path: "/attribute/edit/:id", element: <EditAttribute /> },
      { path: "/species", element: <Species /> },
      { path: "/species/create", element: <CreateSpecies /> },
      { path: "/species/edit/:id", element: <EditSpecies /> },
      { path: "/packages", element: <Packages /> },
      { path: "/packages/create", element: <CreatePackage /> },
      { path: "/packages/edit/:id", element: <EditPackage /> },
      { path: "/layers", element: <Layers /> },
      { path: "/layers/create", element: <CreateLayers /> },
      { path: "/layers/edit/:id", element: <EditLayer /> },
      { path: "/blogs", element: <GetContent /> },
      { path: "/blogs/create", element: <BlogPage /> },
      { path: "/blogs/edit/:id", element: <EditContent /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/about-us/create", element: <AboutUsForm /> },
      { path: "/about-us/edit/:id", element: <AboutUsEditForm /> },
      { path: "/hero", element: <HeroPage /> },
      { path: "/hero/create", element: <CreateHero /> },
      { path: "/hero/edit/:id", element: <EditHero /> },
      { path: "/gallery/images", element: <ImageList /> },
      { path: "/gallery/videos", element: <VideoList /> },
      { path: "/gallery/video/create", element: <CreateVideo /> },
      { path: "/gallery/video/edit/:id", element: <EditVideo /> },
      { path: "/gallery/image/create", element: <CreateImage /> },
      { path: "/gallery/image/edit/:id", element: <EditImage /> },
      { path: "/landing/order", element: <Order /> },
      { path: "/landing/order/create", element: <CreateOrder /> },
      { path: "/landing/order/edit/:id", element: <EditOrder /> },
      { path: "/landing/who-we-are", element: <WhoWeAre /> },
      { path: "/landing/who-we-are/create", element: <CreateWhoWeAre /> },
      { path: "/landing/who-we-are/edit/:id", element: <EditWhoWeAre /> },
      { path: "/company-info", element: <CompanyInfo /> },
      { path: "/company-info/edit/:id", element: <EditCompanyInfo /> },
    ],
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
); // If you want to start measuring performance in your app, pass a function // to log results (for example: reportWebVitals(console.log)) // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals reportWebVitals();
