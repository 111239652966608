import { gql, useQuery } from "@apollo/client";

export const FIND_GALLERY_IMAGE = gql`
  query GetGalleryImage($id: String!) {
    galleryList(where: { id: $id }) {
      id
      createdAt
      updatedAt
      images {
        id
        url
        mobileIndex
        desktopIndex
      }
      translations {
        id
        name
        language
      }
    }
  }
`;

export const useGetOneGalleryImage = (id: string | any) => {
  const { data, loading, error } = useQuery(FIND_GALLERY_IMAGE, {
    variables: { id },
    skip: !id,
  });

  return {
    data: data?.galleryList || null,
    loading,
    error,
  };
};
