import { useState } from "react";
import { Spin, List, Card, Modal, Button, Popconfirm } from "antd";
import {
  PlayCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useGetAllGalleryVideos } from "../../../graphql/queries/gallery/Gallery-video/getVideo";
import { GalleryVideo } from "../../../types/gallery/videoTypes";
import { Link } from "react-router-dom";
import { useDeleteVideo } from "../../../graphql/mutations/Gallery/Gallery-video/deleteVideo";

const VideoList = () => {
  const { data, loading, error, refetch } = useGetAllGalleryVideos();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { handleDeleteVideo, loading: deleteLoading } = useDeleteVideo(refetch);
  const handleThumbnailClick = (url: string) => {
    setVideoUrl(url);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setVideoUrl(null);
  };

  const handleDelete = async (
    videoId: string,
    url: string,
    thumbnailName: string
  ) => {
    const videoName = url.split("/").pop();

    const result = await handleDeleteVideo(videoId, videoName, thumbnailName);
    if (result.success) {
      console.log(`Deleted video with ID: ${videoId}`);
    } else {
      console.error("Failed to delete video:", result.error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error loading videos.</p>;

  return (
    <div style={{ padding: "20px" }}>
      <Link to="/gallery/video/create">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "40px" }}
        >
          ვიდეოს დამატება
        </Button>
      </Link>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        }}
        dataSource={data}
        renderItem={(item: GalleryVideo) => (
          <List.Item style={{ marginBottom: "16px" }}>
            <Card
              cover={
                <div style={{ position: "relative" }}>
                  <img
                    alt="thumbnail"
                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.thumbnailUrl}`}
                    onClick={() => handleThumbnailClick(item.url)}
                    style={{
                      cursor: "pointer",
                      width: "200px",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                  <PlayCircleOutlined
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "2rem",
                      color: "#fff",
                      background: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      padding: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => handleThumbnailClick(item.url)}
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Link to={`/gallery/video/edit/${item.id}`}>
                      <Button
                        size="small"
                        icon={<EditOutlined />}
                        style={{ background: "rgba(255, 255, 255, 0.7)" }}
                      />
                    </Link>
                    <Popconfirm
                      title="დარწმუნებული ხარ რომ ამ ვიდეოს წაშლა გინდა?"
                      onConfirm={() =>
                        handleDelete(item.id, item.url, item.thumbnailUrl)
                      }
                      okText="კი"
                      cancelText="არა"
                    >
                      <Button
                        size="small"
                        icon={<DeleteOutlined />}
                        style={{ background: "rgba(255, 255, 255, 0.7)" }}
                      />
                    </Popconfirm>
                  </div>
                </div>
              }
              style={{
                border: "none",
                padding: 0,
              }}
            />
          </List.Item>
        )}
      />
      <Modal
        title="Video"
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="80%"
        style={{ textAlign: "center" }}
      >
        {videoUrl && (
          <video
            controls
            autoPlay
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          >
            <source
              src={`${process.env.REACT_APP_API_URL}/uploads/${videoUrl}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
    </div>
  );
};

export default VideoList;
