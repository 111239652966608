import { gql, useQuery } from "@apollo/client";

const GET_ALL_ORDERS = gql`
  query MyQuery {
    orders {
      id
      kaName
      enName
      kaDescription
      enDescription
    }
  }
`;

export const useGetAllOrders = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_ORDERS);

  return {
    data: data?.orders || [],
    loading,
    error,
    refetch,
  };
};
