import { useState } from "react";
import { Form, Input, Button, Upload, Spin, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { useNavigate } from "react-router-dom";
import { useCreateGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/createImage";
import { useGetAllGalleryImages } from "../../../graphql/queries/gallery/Gallery-image/getImage";

const { Item: FormItem } = Form;

const CreateImage = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const navigate = useNavigate();
  const { refetch } = useGetAllGalleryImages();
  const { createGalleryImage, loading } = useCreateGalleryImage(() => {
    refetch();
    navigate("/gallery/images");
  });

  const handleSubmit = async (values: any) => {
    const { nameEn, nameKa } = values;

    if (fileList.length === 0) {
      console.error("No image file selected");
      return;
    }

    const uploadPromises = fileList.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file.originFileObj as Blob);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      return data.url;
    });

    try {
      const uploadedUrls = await Promise.all(uploadPromises);

      const imagesData = uploadedUrls.map((url, index) => ({
        url,
        mobileIndex: Number(values[`mobileIndex_${index}`]),
        desktopIndex: Number(values[`desktopIndex_${index}`]),
      }));

      await createGalleryImage({
        variables: {
          data: {
            images: {
              create: imagesData,
            },
            translations: {
              create: [
                {
                  language: "EN",
                  name: nameEn,
                },
                {
                  language: "KA",
                  name: nameKa,
                },
              ],
            },
          },
        },
      });
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2>სურათების ლისტის დამატება</h2>
      {loading && <Spin />}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          name="nameEn"
          label="სახელი ინგლისურად"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ სახელი ინგლისურად" },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name="nameKa"
          label="სახელი ქართულად"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ სახელი ქართულად" },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name="images"
          label="ატვირთეთ სურათი"
          rules={[
            { required: true, message: "გთხოვთ ატვირთოთ მინიმუმ 1 სურათი" },
          ]}
        >
          <Upload
            fileList={fileList}
            beforeUpload={(file) => {
              setFileList((prevList) => [...prevList, file]);
              return false;
            }}
            onChange={handleFileChange}
            listType="picture-card"
            accept="image/*"
            multiple
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>ატვირთვა</div>
            </div>
          </Upload>
        </FormItem>

        {fileList.map((file, index) => (
          <Row key={index} gutter={16}>
            <Col span={12}>
              <FormItem
                name={`mobileIndex_${index}`}
                label={`ინდექსი მობაილისთვის - ${file.name}`}
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ ინდექსი მობაილისთვის",
                  },
                ]}
              >
                <Input type="number" min={1} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={`desktopIndex_${index}`}
                label={`ინდექსი დესკტოპისთვის - ${file.name}`}
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ ინდექსი დესკტოპისთვის",
                  },
                ]}
              >
                <Input type="number" min={1} />
              </FormItem>
            </Col>
          </Row>
        ))}

        <FormItem>
          <Button type="primary" htmlType="submit" loading={loading}>
            დამატება
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default CreateImage;
